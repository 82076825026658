import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
})
export class LandingComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }

  gotoMyk(): void {
    window.open('https://www.myk.gov.tr/', '_blank');
  }

  scrollToElement($element): boolean {
    $element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
    return false;
  }

  str: string = 'Laboratuarlar, muayene ve belgelendirme kuruluşları; Avrupa Akreditasyon İşbirliği Programı\'nın (EA) üyesi bir ulusal akreditasyon kuruluşu veya karşılıklı tanınma anlaşması yaptığı bir bölgesel akreditasyon kuruluşu tarafından akredite edilmiş ise bu belgelendirme kuruluşlarının verdiği belgeler, uluslararası alanlarda geçerlidir ve kabul görür. Bu kuruluşlar düzenli aralıklarla Ulusal ve Uluslararası Akreditasyon kurumlarınca denetlenirler.';
}
