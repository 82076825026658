import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-documents",
  templateUrl: "./iskele-kurulum-elemani.component.html",
})
export class IskeleKurulumElemaniComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
