import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kimya-petrol-lastik-plastik-meslekleri',
  templateUrl: './kimya-petrol-lastik-plastik-meslekleri.component.html',
})
export class KimyaPetrolLastikPlastikMeslekleriComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
