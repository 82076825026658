import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-metal-meslekleri',
  templateUrl: './metal-meslekleri.component.html',
})
export class MetalMeslekleriComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
