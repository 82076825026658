import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";


// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { MapsComponent } from "./views/admin/maps/maps.component";
import { SettingsComponent } from "./views/admin/settings/settings.component";
import { TablesComponent } from "./views/admin/tables/tables.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";

// no layouts views
import { IndexComponent } from "./views/index/index.component";
import { LandingComponent } from "./views/landing/landing.component";
import { Ahsap_kalipciComponent} from './views/Jobs/ahsap_kalipci/ahsap_kalipci.component';
import { DocumentsComponent} from './views/documents/documents.component';
import {AkreditasyonComponent} from './views/akreditasyon/akreditasyon.component';
import {FiyatlistesiComponent} from './views/fiyatlistesi/fiyatlistesi.component';
import {HakkimizdaComponent} from './views/hakkimizda/hakkimizda.component';
import {OrganizasyonyapisiComponent} from './views/organizasyonyapisi/organizasyonyapisi.component';
import {TemsilciliklerComponent} from './views/temsilcilikler/temsilcilikler.component';
import {TarafsizlikComponent} from './views/tarafsizlik/tarafsizlik.component';
import {MeslekiyeterlilikveakreditasyonComponent} from './views/meslekiyeterlilikveakreditasyon/meslekiyeterlilikveakreditasyon.component';
import {LogosComponent} from './views/logos/logos.component';
import {FormsComponent} from './views/forms/forms.component';
import {ExammapsComponent} from './views/maps/exammaps.component';
import {DuyurularComponent} from './views/News/duyurular/duyurular.component';
import {NewsComponent} from './views/News/haberler/news.component';
import {Alci_sivaComponent} from './views/Jobs/alci_siva/alci_siva.component';
import {BetonarmeDemirciComponent} from './views/Jobs/betonarme-demirci/betonarme-demirci.component';
import {DuvarciComponent} from './views/Jobs/duvarci/duvarci.component';
import {SivaciComponent} from './views/Jobs/sivaci/sivaci.component';
import {SeramikKaroKaplamacisiComponent} from './views/Jobs/seramik-karo-kaplamacisi/seramik-karo-kaplamacisi.component';
import {InsaatBoyacisiComponent} from './views/Jobs/insaat-boyacisi/insaat-boyacisi.component';
import {IskeleKurulumElemaniComponent} from './views/Jobs/iskele-kurulum-elemani/iskele-kurulum-elemani.component';
import {AluminyumKaynakcisiComponent} from './views/Jobs/aluminyum-kaynakcisi/aluminyum-kaynakcisi.component';
import {CelikKaynakcisiComponent} from './views/Jobs/celik-kaynakcisi/celik-kaynakcisi.component';
import {InsaatIscisiComponent} from './views/Jobs/insaat-iscisi/insaat-iscisi.component';
import {DirencKaynakAyarcisiComponent} from './views/Jobs/direnc-kaynak-ayarcisi/direnc-kaynak-ayarcisi.component';
import {ElektrikElektronikMeslekleriComponent} from './views/Jobs/jobs-category/elektrik-elektronik-meslekleri/elektrik-elektronik-meslekleri.component';
import {InsaatMeslekleriComponent} from './views/Jobs/jobs-category/insaat-meslekleri/insaat-meslekleri.component';
import {KimyaPetrolLastikPlastikMeslekleriComponent} from './views/Jobs/jobs-category/kimya-petrol-lastik-plastik-meslekleri/kimya-petrol-lastik-plastik-meslekleri.component';
import {MakineMeslekleriComponent} from './views/Jobs/jobs-category/makine-meslekleri/makine-meslekleri.component';
import {UlastirmaLojistikHaberlesmeMeslekleriComponent} from './views/Jobs/jobs-category/ulastirma-lojistik-haberlesme-meslekleri/ulastirma-lojistik-haberlesme-meslekleri.component';
import {VincTasiyiciSapanciMeslekleriComponent} from './views/Jobs/jobs-category/vinc-tasiyici-sapanci-meslekleri/vinc-tasiyici-sapanci-meslekleri.component';
import {MetalMeslekleriComponent} from './views/Jobs/jobs-category/metal-meslekleri/metal-meslekleri.component';
const routes: Routes = [
  // admin views
  // no layout views
  {path: "insaat-meslekleri", component: InsaatMeslekleriComponent},
  {path:"elektrik-elektronik-meslekleri", component: ElektrikElektronikMeslekleriComponent},
  {path: "makine-meslekleri", component: MakineMeslekleriComponent},
  {path: "kimya-petrol-lastik-plastik-meslekleri", component: KimyaPetrolLastikPlastikMeslekleriComponent},
  {path: "ulastirma-lojistik-haberlesme", component: UlastirmaLojistikHaberlesmeMeslekleriComponent},
  {path: "vinc-tasiyici-sapanci-meslekleri", component: VincTasiyiciSapanciMeslekleriComponent},
  {path: "metal-meslekleri", component: MetalMeslekleriComponent},

  {path:"direnc-kaynak-ayarcisi", component:DirencKaynakAyarcisiComponent},
  {path:"insaat-iscisi", component:InsaatIscisiComponent},
  {path:"celik-kaynakcisi", component:CelikKaynakcisiComponent},
  {path:"aluminyum-kaynakcisi", component:AluminyumKaynakcisiComponent},
  {path:"iskele-kurulum-elemani", component:IskeleKurulumElemaniComponent},
  {path:"insaat-boyacisi", component:InsaatBoyacisiComponent},
  {path:"seramik-karo-kaplamacisi", component:SeramikKaroKaplamacisiComponent},
  {path:"sivaci", component:SivaciComponent},
  {path:"duvarci", component:DuvarciComponent},
  {path:"betonarme-demirci", component:BetonarmeDemirciComponent},
  {path:"alci-siva", component:Alci_sivaComponent},
  {path:"duyurular", component:DuyurularComponent},
  {path:"news", component:NewsComponent},
  {path:"exam-maps", component:ExammapsComponent},
  {path: "forms", component: FormsComponent},
  {path:"logos", component: LogosComponent},
  {path:"myeterlilikakreditasyon", component: MeslekiyeterlilikveakreditasyonComponent},
  {path:"tarafsizlik", component:TarafsizlikComponent},
  {path:"temsilcilikler", component:TemsilciliklerComponent},
  {path:"organizasyonyapisi", component: OrganizasyonyapisiComponent},
  {path: "hakkimizda", component: HakkimizdaComponent},
  { path: "documents", component:  DocumentsComponent},
  {path: "ahsapkalipci", component:Ahsap_kalipciComponent},
  {path: "akreditasyon" , component:AkreditasyonComponent},
  {path:"fiyatlistesi", component:FiyatlistesiComponent},
  { path: "landing", component: LandingComponent },
  { path: "", component: LandingComponent },
  { path: "**", redirectTo: "/landing", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
