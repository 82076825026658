import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-ahsapkalipci",
  templateUrl: "./organizasyonyapisi.component.html",
})
export class OrganizasyonyapisiComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
