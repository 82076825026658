import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-documents",
  templateUrl: "./direnc-kaynak-ayarcisi.component.html",
})
export class DirencKaynakAyarcisiComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
