import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-ahsapkalipci",
  templateUrl: "./forms.component.html",
})
export class FormsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
