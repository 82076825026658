import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ulastirma-lojistik-haberlesme-meslekleri',
  templateUrl: './ulastirma-lojistik-haberlesme-meslekleri.component.html',
})
export class UlastirmaLojistikHaberlesmeMeslekleriComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
