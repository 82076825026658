import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { createPopper } from "@popperjs/core";
import {Router} from '@angular/router';

@Component({
  selector: 'app-hizmetler-dropdown',
  templateUrl: './hizmetler_drop_down.component.html',
})
export class Hizmetler_drop_downComponent implements OnInit {
  dropdownPopoverShow = false;
  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;
  constructor(private _router:Router) {
  }
  ngOnInit() {}
  toggleDropdown(event) {
    event.preventDefault();
    if (this.dropdownPopoverShow) {
      this.dropdownPopoverShow = false;
    } else {
      this.dropdownPopoverShow = true;
      this.createPoppper();
    }
  }
  onClickedOutside(e: Event) {
    this.dropdownPopoverShow = false;
  }
  createPoppper() {
    createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef.nativeElement,
      {
        placement: "bottom-start",
      }
    );
  }
  scrollToElement($element): void {

    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
    this._router.navigateByUrl("/landing"+ $element);
  }
}
